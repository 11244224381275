.mailto-icon{
  margin-top: -5px
}

.dashboard-user-details ul {
  padding-left: 0px
}

.dashboard-user-details li {
  list-style: none;
}