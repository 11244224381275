input.MuiInput-input{
    width: 100%;
    background-color: #f0f2f5;
    border-radius: 100px;
    border: none;
    padding: 20px 30px 10px 30px;
    height: unset;
}

.MuiSelect-root .MuiSvgIcon-root{
    position: absolute;
    right: 17px;
    top: 16px;
}

.wb-input-wrapper textarea{
    width: 100%;
    background-color: #f0f2f5;
    height: 169px;
    overflow: hidden;
    border-radius: 24px;
    border: none;
    padding: 20px 30px 10px 30px;
}

.wb-input-wrapper{
    position: relative;
}

.wb-input-wrapper label{
    position: absolute;
    z-index: 1;
    top: 0;
    font-size: 12px;
    line-height: 10px;
    color: #8c939c;
    margin-top: 4px;
    left: 30px;
}

.btn{
    padding: 12px 24px;
    display: inline-block;
    margin-right:24px;
    border-radius: 60px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600 !important;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    
    @media all and (min-width: 1024px){
        font-size: 13px;
    }

    @media all and (min-width: 1440px){
        font-size: 16px;
    }
}

.float-left{ float: left; }
.float-right{ float: right; }

.btn.prim{
    background-color: #3a8787;
    border: 1px solid #3a8787;
    color: #fff;
}

.btn.prim:hover{
    background-color: #1f2326;
}

.btn.sec{
    border: 1px solid #ced5de;
    background-color: white;
    color: #1f2326;
}

.btn.sec:hover{
    border: 1px solid #2c7373;
    background-color: #e4e6eb;
}
